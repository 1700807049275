// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');

@import "assets/css/cssvariables.scss";

body{margin: 0; padding: 0; font-size: $base-font-size; font-family: $base-font; background: #eaeaea; color: $text-color;}
.text-center {text-align: center!important;}
.text-right{text-align: right}
.text-uppercase{text-transform: uppercase}
.clear{clear: both; display: block;}

/* container */
.box{padding:10px 20px; background: #fff;}
