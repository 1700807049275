/**
 * Variables for colors
 */
 $text-color: #58666e;
 $base-color:#3862c8;

 // Font-Size
 $base-font: 'Rubik', sans-serif !important;
 $base-font-size: 1em;


 