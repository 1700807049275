

body,html{min-height: 100%; background:#f1f1f7; padding: 0; margin: 0; font-family: sans-serif;}
a, .btn_link{color: #007bff; text-decoration:none}
.uppercase{text-transform: uppercase;}
.btn{cursor: pointer;}
.btn_link{font-size:14px; cursor: pointer;}
.con_content h2{display: inline; vertical-align: middle}
.fleft{float: left;}
.fright{float: right}
.spacer5{display: block; clear: both; height: 5px;}
.spacer10{display: block; clear: both; height: 10px;}
.spacer20{display: block; clear: both; height: 20px;}
.divider5{display: inline-block; width: 5px;}
.divider10{display: inline-block; width: 10px;}

.defaultColor{color: dimgrey}
.text-color-dim{color: dimgrey}
.text-fadded *{color: #c3c3c3}

.w100{width:100% !important}
.btn.big{padding: 10px 20px; font-size: 14px; text-transform: uppercase}
.flex-container{display: flex}
.hide{display: none !important;}
.novisible{visibility: hidden;}
.boxshadow{box-shadow: 2px 1px 5px 0px #c3c3c3;}
.boxradius{border-radius: 3px;}
.cntrlradius{border-radius: 2px;}
h1,h2,h3,h4,h5,h6{padding: 0 !important; margin: 0;}
h2{font-size: 1.7rem;}
.mr0{margin: 0;}
.mrb0{margin-bottom: 0 !important;}
.pad20{padding: 20px;}
.pad40{padding: 40px;}

.font-xs{font-size: .75rem;}
.font-small{font-size: .95rem;}

.hrline{display: block; border: solid 0.5px #e0dddd; width: 100%;}

/* for card top border */
.bTop{border-top: solid 4px #007bff;}
.caret.caret-up {border-top-width: 0; border-bottom: 4px solid #fff;}
.caret{border: 4px solid transparent; border-top: 4px solid #000;}

.color_default{color:#777}
.color_primary{color: #337ab7;}
.color_success{color: #5cb85c}
.color_info{color: #5bc0de}
.color_warning{color: #f0ad4e}
.color_danger{color: #d9534f}

.bg-white{background-color: #fff;}

/* Logo */
.con_logo{padding: 20px 10px 10px 10px; width: 150px; padding-left: 0 !important; margin: auto;}
.con_logo img{max-width: 100%;}

#main{min-height: 100vh; padding: 0 !important; margin-left: 250px;}
.content{padding:100px 5px 5px; max-width: 1300px; margin: auto;}
@media screen and (min-width: 800px) and (-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi) {
  .content{padding:100px 30px 30px;}
}
.container-fluid{background-color: #f1f1f7;}

/* Heading */
/* .content-header{margin-bottom:10px !important;} */
.content-header *{color: #595d6e; margin-bottom: 0; vertical-align: middle;}
/* .content-header h3{text-transform: uppercase;} */
.content-header h3, .content-header ul{display: inline-block; text-transform: uppercase;}
.content-header .col-sm-12{padding: 0 0 0 0;}
.pageheading h3{font-size: 20px; vertical-align: -webkit-baseline-middle; font-weight: bold;}


/* Labels */
.badge{text-transform: uppercase;}
.badge-primary{background-color: #007bff1a; border: solid 1px #007bff; color: #007bff;}
.badge-secondary{background-color: #6c757d1a; border: solid 1px #6c757d; color: #6c757d;}
.badge-info{background-color: #17a2b81a; border: solid 1px #17a2b8; color: #17a2b8;}
table .badge{margin: auto; min-width: 30px; font-size: 12px; font-weight: normal;}

/* Progress Bar */
.progress{position: relative; background-color: #a9a9a9;}
.progressCount{position: absolute; display: block; margin: auto; right: 0; left: 0; color: #fff; font-weight: bold;}

/* constainer */
.btngroup{text-align: right;}
.btngroup *{cursor: pointer;}
.tbl_action th:last-child, .tbl_action td:last-child{text-align: right;}
.nodrop::after{display: none;}

/* Table Styling */
.con_table{padding: 0 20px; background-color: #fff; border: solid 1px #dee2e6}
.table th{border-top:none}

.dataTable{border: solid 0.5px #dee2e6;}
.dataTable th{text-transform: uppercase; font-size: 12px;}
.dataTable th, .dataTable td{padding: 15px 8px !important;}
.dataTable{font-size: 15px;}
.dataTable a:not(.btn){color: #000;} 
.dataTable a:not(.btn):hover{color:#007bff; text-decoration: underline;} 
.con_loader{position: absolute; height: 100%; width: 100%;}
.con_loader>div{position: absolute; width: 300px; height: 50px; margin: auto; top: 0; bottom: 0; right: 0; left: 0;}

// filters
.filters{text-align: right;}

/* Action Button in Table */
table .btn{padding:0 5px;}

/* icons */
i{font-size: 15px !important; vertical-align: bottom; line-height: 1.5rem !important;}
.btn-sm i{font-size: 15px !important; vertical-align: bottom; line-height: 20px !important;}
nav i{font-size: 19px !important;}
button i{vertical-align: text-bottom;}


/* Breadcrumb */
ul.breadcrumb{padding:3px 0;list-style:none;background-color:transparent; margin-left: 40px;}
ul.breadcrumb li{display:inline;font-size:15px; color: #959cb6;}
ul.breadcrumb li+li:before{padding:8px;color:#959cb6;content:"\00B7";}
ul.breadcrumb li a{color:#0275d8;text-decoration:none;}
ul.breadcrumb li a:hover{color:#01447e;text-decoration:underline;}


/* Date time picker */
.datepicker{padding: 0;}



/* Custom Check Box */
.regular-checkbox {-webkit-appearance: none;background-color: #fff;border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 9px;border-radius: 3px;display: inline-block;position: relative; outline: none}
.regular-checkbox:active, .regular-checkbox:checked:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);}

.regular-checkbox:checked {background-color: #fff;border: 1px solid #adb8c0;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: #99a1a7;}
.regular-checkbox:checked:after {content: '\2713';font-size: 14px;position: absolute;top: 0px;left: 3px;color: #007bff;}

/* For Popup Container */
.popup{position: fixed; margin: auto; top:0; bottom: 0; right: 0; left: 0; background: #fff; width: 600px; height: 80vh;
        overflow: auto; z-index: 100;border-radius: 5px; display: none}
.popup .pop_inner{position: relative;}
.overlay, .overlayInner{position: fixed; z-index: 99; background: #212121; top:0; bottom: 0; right: 0; left: 0; opacity: .9; display: none}
.overlayInner{background-color: #fff !important; position: absolute;}
.overlayInner .loader{display: none;}
.overlayInner.active .loader{display: block;}
.popup.active, .overlay.active, .overlayInner.active{display: block}
.popup .close_pop::after{content: '\2573'; font-size: 25px; color: #000; display: inline-block; padding: 5px; position: absolute; top: 5px; right: 5px;}
.pop_form .con_InnerForm{width: 100%; padding: 20px;}
.pop_form .form{padding: 0; margin-top: 30px}
/* pop ends here */

// popup right
.rightpopup{position: absolute; right: 0; width: 350px; top: 0; bottom: 0; z-index: 999; background: #fff; box-shadow: -1px 0px 12px 2px #c3c3c3; border-collapse: collapse; padding: 20px;}


/* For file upload thumb */
.add_file_thumb{display:block; margin: auto; margin-top: 50px; width: 200px; height: 200px; border:dashed 1px #c3c3c3; position: relative;}
.add_file_thumb img{max-height: 60%; max-width: 90%; position: absolute; margin: auto; top:0; bottom: 0; right: 0; left: 0; z-index: 11; background-color: #fff;}
.add_file_thumb i{position: absolute; font-size: 50px !important; height: 30px; width: 50px; margin: auto; top:0; bottom: 0; left: 0; right: 0; z-index: 10;}
.add_file_thumb span{display: block; margin: auto; text-align: center; text-transform: uppercase; margin-top: 10px;}
.add_file_thumb .textBottom{display: block; margin: auto; width: 100%; text-align: center; text-transform: uppercase; font-size: 11px; position: absolute; bottom: 10px;}

.icon_sm{display: inline-block; margin: 2px 5px; text-align: center; padding: 8px; box-sizing: border-box; border-radius: 3px; color: #fff; cursor: pointer; font-size: 12px; text-transform: uppercase}
.icon_sm.edit{background-color: yellowgreen;}
.icon_sm.del{background-color: tomato}

/* Messgae Summary Prompt */
.msg{box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12); min-width: 300px; max-width:100%; font-size:16px; text-align: center;
  position: fixed; margin:auto; right: 10px; top:-100px; left:0; z-index: 888; overflow: hidden; padding: .75rem 1.25rem; opacity: .95; box-sizing: border-box;
  border: 1px solid transparent; border-radius: .25rem;}
.msg.msg_success{background-color: #d4edda; color:#155724; border-color: #c3e6cb;}
.msg.msg_warning{background-color: #ffc107; color:#000; border-color: #c3e6cb;}
.msg.msg_error{color: #721c24; background-color: #f8d7da; border-color: #f5c6cb;}
.msg.msg_message{color: #721c24; background-color: #f8d7da; border-color: #f5c6cb;}

.form_error{font-size: 12px; color: #fd5a5a; display: block; width: 100%;}
.fieldError{border:solid 1px #fd5a5a}
/* Messgae Summary Prompt Ends Here */


.row{margin-bottom: 20px}
.row .col{padding: 0 5px 0 5px; box-sizing: border-box}
.row.col2 .col{width: 50%; display: inline-block;}
.row.col3 .col{width: 33.3%; display: inline-block;}


.tbl{display: table; width: 100%}
.tbl .trow{display: table-row}
.tbl .tcol{display: table-cell; vertical-align: top; box-sizing: border-box;}
.tbl .trow.col2 .tcol{width: 50%; display: inline-block;}
.tbl .trow.col3 .tcol{width: 33.3%; display: inline-block;}

.con_middle{width: 100%; margin: auto;}
@media screen and (min-width: 800px) and (-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi) {
  .con_middle{width: 70%; margin: auto; padding: 20px;}
}
.con_block{display: block;}

.loader {border: 16px solid #f3f3f3; border-radius: 50%; border-top: 16px solid blue; border-bottom: 16px solid blue;
    width: 120px; height: 120px; -webkit-animation: spin 2s linear infinite; animation: spin 2s linear infinite; position: absolute;
    margin: auto; right: 0; left: 0; top: 0; bottom: 0;}
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


/* Loader FB Starts here */
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  color: transparent !important;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eeeeee;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  position: relative;
}


@media (min-width: 1000px) and (-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi){
  .modal-dialog {max-width: 800px; margin: 1.75rem auto;}
}



.tab_container{list-style:none; padding: 0; margin: 0; border-bottom: solid 3px #000;}
.tab_container li{display: inline-block; padding: 10px 15px; text-align: center; position: relative; cursor: pointer;}
.tab_container li.active:after, .tab_container li:hover::after{content: ""; display: block; height: 0; width: 0; border: solid 10px transparent; border-top-color: #000; position: absolute; margin: auto; right: 0; left: 0; top: 100%;}


.switch{position:relative;display:inline-block;width:60px;height:34px;}
.switch input{opacity:0;width:0;height:0;}
.slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:.4s;transition:.4s;}
.slider:before{position:absolute;content:"";height:26px;width:26px;left:4px;bottom:4px;background-color:white;-webkit-transition:.4s;transition:.4s;}
input:checked + .slider{background-color:#2196F3;}
input:focus + .slider{box-shadow:0 0 1px #2196F3;}
input:checked + .slider:before{-webkit-transform:translateX(26px);-ms-transform:translateX(26px);transform:translateX(26px);}
.slider.round{border-radius:34px;}
.slider.round:before{border-radius:50%;}


.btn_con{position: relative; display: inline-block;}
.btn_con .con_spinner{position: absolute; padding: 0; margin:auto; top: 0; bottom: 0; left: 0; right: 0; list-style-type: none; height: 16px; text-align: center; line-height: 0; display: none;}


/* for form contorls */
/* Forms */
.form-group label{font-size: 0.85rem;}
.form-control{font-size: 0.95rem; padding: 8px;}
.form-control{color:#6c757d!important}
.con_InnerForm{width: 70%; max-width: 700px; margin: auto; box-sizing: border-box;}
.custom-control-label{line-height: 1.5rem;}

/* Select box */
select{-webkit-appearance:none;-moz-appearance:none;-ms-appearance:none;appearance:none;outline:0;box-shadow:none; background:#e9ecef;background-image:none;}
select::-ms-expand{display:none;}
.select{position:relative; overflow:hidden; border-radius:.25em; min-width: 200px;}
select{flex:1; color:#fff;cursor:pointer;}
.select::after{content:'\25BC'; height: 38px; margin: 1px; border-radius: .25rem; position:absolute; top:0; right:0; padding:10px 1em; background:#e9ecef;cursor:pointer;pointer-events:none;-webkit-transition:.25s all ease;-o-transition:.25s all ease;transition:.25s all ease;}
.select:hover::after{color:#000;}


/* for custon checkbox Start Here*/
.custom_checkbox{display: block; position: relative; padding-left: 30px; cursor: pointer;
    -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
.custom_checkbox input[type="checkbox"] {position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0;}
.custom_checkbox .checkmark {position: absolute; top: 0; left: 0; height: 20px; width: 20px; background-color: #eee;}
.custom_checkbox:hover input ~ .checkmark {background-color: #ccc;}
.custom_checkbox input:checked ~ .checkmark {background-color: #2196F3;}
.custom_checkbox .checkmark:after {content: "";position: absolute;display: none;}
.custom_checkbox input:checked ~ .checkmark:after {display: block;}
.custom_checkbox .checkmark:after {left: 6px; top: 2px; width: 5px; height: 10px; border: solid white;
border-width: 0 3px 3px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg);}

/* Another way of doing */
.scheckbox{display:block;position:relative;padding-left:35px;margin-bottom:12px;cursor:pointer;font-size:22px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
.scheckbox input{position:absolute;opacity:0;cursor:pointer;height:0;width:0;}
.scheckbox{position:absolute;top:0;left:0;height:25px;width:25px;background-color:#eee;}
.scheckbox:hover input ~ .checkmark{background-color:#ccc;}
.scheckbox input:checked ~ .checkmark{background-color:#2196F3;}
.scheckbox:after{content:"";position:absolute;display:none;}
.scheckbox input:checked ~ .checkmark:after{display:block;}
.scheckbox .checkmark:after{
  left:9px;top:5px;width:5px;height:10px;border:solid white;border-width:0 3px 3px 0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);
}

/* for custon checkbox Ends Here*/

/* For Custom Radio Button */
/* The container */
.custom_radioButtton {display: block; position: relative; padding-left: 35px; margin-bottom: 12px; cursor: pointer; font-size:15px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
  
/* Hide the browser's default radio button */
.custom_radioButtton input { position: absolute; opacity: 0; cursor: pointer; }

/* Create a custom radio button */
.custom_radioButtton .checkmark { position: absolute; top: 0; left: 0; height: 25px; width: 25px; background-color: #eee; border-radius: 50%; }

/* On mouse-over, add a grey background color */
.custom_radioButtton:hover input ~ .checkmark { background-color: #ccc; }

/* When the radio button is checked, add a blue background */
.custom_radioButtton input:checked ~ .checkmark { background-color: #2196F3; }

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom_radioButtton .checkmark:after { content: ""; position: absolute; display: none; }

/* Show the indicator (dot/circle) when checked */
.custom_radioButtton input:checked ~ .checkmark:after { display: block; }

/* Style the indicator (dot/circle) */
.custom_radioButtton .checkmark:after { top: 9px; left: 9px; width: 8px; height: 8px; border-radius: 50%; background: white; }

.selectd_label{display: inline-block; padding: 5px 10px 5px 32px; background: #fff; border-radius: 15px;}
.custom_radioButtton.selectd_label .checkmark{top:2px; left:2px;}
.selectd_label.active{box-shadow: 3px 3px 3px 1px #c3c3c3;}

/* For Custom Drop Down */
/* Custom dropdown */
.custom-dropdown{position:relative;display:inline-block;vertical-align:middle; width: 100%;}
.custom-dropdown select{font-size:inherit;padding:.5em;padding-right:2.5em;border:0;margin:0;border-radius:3px;text-indent:0.01px;text-overflow:'';-moz-appearance:none;-webkit-appearance:none;appearance:none; background-color: #fff;}
.custom-dropdown select::-ms-expand{display:none;}
.custom-dropdown::before,.custom-dropdown::after{content:"";position:absolute;pointer-events:none;}
.custom-dropdown::after{content:"\25BC";height:1em;font-size:.625em;line-height:1;right:1.2em;top:50%;margin-top:-.5em;}
.custom-dropdown::before{width:2em;right:0;top:0;bottom:0;border-radius:0 3px 3px 0;background-color:rgba(0,0,0,.2);}
.custom-dropdown::after{color:rgba(0,0,0,.6);}
.custom-dropdown select[disabled]{color:rgba(0,0,0,.25);}

/* Drop Menu */
.dropdown-menu{min-width: 200px;}
.dd_filter{padding: 10px;}
.dd_filter .con_filter{max-height: 300px; overflow: scroll;}
.dd_filter .dropdown-header{font-weight: bold; text-align: left; padding: 5px 0px;}
.dd_filter .filter_dd_content{font-size: 0.9rem;}

/* Place Holders */
::placeholder { color: #bbbcbd !important; opacity: 1; /* Firefox */ }
:-ms-input-placeholder { /* Internet Explorer 10-11 */ color: #bbbcbd !important; }
::-ms-input-placeholder { /* Microsoft Edge */ color: #bbbcbd !important; }

/* scroll bar */
.custom-scrollbar { overflow: auto; }
/* scrollbar width */
.custom-scrollbar::-webkit-scrollbar { width: 2px;}
/* scrollbar track */
.custom-scrollbar::-webkit-scrollbar-track { background: #fff; }
/* scrollbar handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #00d2ff;
  background-image: linear-gradient(to top, #00d2ff 0%, #3a7bd5 100%);
}
